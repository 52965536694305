// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-1ui3wbn-MuiInputBase-root-MuiTablePagination-select {
    max-width: 60px !important;
}

.css-s7qg2b-MuiTablePagination-displayedRows {
    padding-top: 12px !important;
}
.css-rroo7h-MuiTablePagination-selectLabel{
    padding-top: 10px !important;
    padding-right: 5px !important;
}
.css-74bi4q-MuiDataGrid-overlayWrapper {
    height: 60px !important;
}



`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;AAC9B;;AAEA;IACI,4BAA4B;AAChC;AACA;IACI,4BAA4B;IAC5B,6BAA6B;AACjC;AACA;IACI,uBAAuB;AAC3B","sourcesContent":[".css-1ui3wbn-MuiInputBase-root-MuiTablePagination-select {\n    max-width: 60px !important;\n}\n\n.css-s7qg2b-MuiTablePagination-displayedRows {\n    padding-top: 12px !important;\n}\n.css-rroo7h-MuiTablePagination-selectLabel{\n    padding-top: 10px !important;\n    padding-right: 5px !important;\n}\n.css-74bi4q-MuiDataGrid-overlayWrapper {\n    height: 60px !important;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
