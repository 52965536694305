// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hover-effect {
  cursor: pointer;
  transition: all 0.3s ease;
}

.hover-effect:hover {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}`, "",{"version":3,"sources":["webpack://./src/components/UserModal/UserModal.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,8CAA8C;EAC9C,sBAAsB;AACxB","sourcesContent":[".hover-effect {\n  cursor: pointer;\n  transition: all 0.3s ease;\n}\n\n.hover-effect:hover {\n  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);\n  transform: scale(1.05);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
